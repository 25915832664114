import React from 'react'

import { useQuery, UserRoles } from 'queries'

export const useRoles = () => {
  const { data } = useQuery(UserRoles)
  return data ? data.me.roles : []
}

export const HasRole = ({ role, children }) => {
  const roles = useRoles()
  if (roles.includes(role)) { return children }
  return null
}

export const HasAny = ({ roles, children }) => {
  const userRoles = useRoles()
  if (userRoles.filter(v => roles.includes(v)).length > 0) { return children }
  return null
}

const wrapHasRole = (role) => ({ children }) => <HasRole role={role} children={children}/>
const wrapHasAny = (roles) => ({ children }) => <HasAny roles={roles} children={children}/>

export const IsAdmin = wrapHasRole("admin")
export const IsAccounting = wrapHasAny(["accounting", "receivables", "collections"])
