import React from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'

import MaterialTable from 'components/table'
import { useQuery, CarriersIndex } from 'queries'
import CarrierView from './view'

export default function CarriersRouting() {
  return (
    <Switch>
      <Route exact path="/logistics/carriers" component={Index}/>
      <Route exact path="/logistics/carriers/:id" component={CarrierView}/>
    </Switch>
  )
}

const Index = (params) => {
  const history = useHistory()
  const { data, loading, refetch } = useQuery(CarriersIndex, { notifyOnNetworkStatusChange: true })

  return (
    <MaterialTable
      title="Carriers"
      data={data ? data.carriers : []}
      refresh={refetch}
      isLoading={loading}
      onRowClick={(_, row) => history.push(`/logistics/carriers/${row.id}`)}
      columns={[
        { title: "Name", field: "name", shrink: true },
        { title: "MC", field: "mcNumber" },
        { title: "Phone", field: "phone" },
        { title: "Factor", field: "factor.name" },
      ]}
      options={{
        pageSize: 20,
      }}
      />
  )
}
