import { useSnackbar } from 'notistack'
import { useDirectUpload, ImportFile } from 'queries'

// returns a function to trigger a file import for name importer
// this in turn gives back a promise that gets resolved when
// the upload has been completed.
export const useFileImport = (name, options = {}) => {
  const upload = useDirectUpload(ImportFile, { import: name })
  const { enqueueSnackbar } = useSnackbar()

  return () => new Promise((resolve, reject) => {
    let input = document.createElement('input')
    input.type = 'file'
    input.onchange = (ev) => {
      const { files } = ev.target
      if (files.length === 0) { return resolve(null) }

      upload(files[0])
        .then(() => {
          if (options.snackbar) {
            enqueueSnackbar("Import Successfully Initiated", { variant: "success" })
          }
          resolve(files[0])
        })
        .catch(err => {
          if (options.snackbar) {
            enqueueSnackbar(`Import Failure: ${err}`, { variant: "error" })
          }
          reject(files[0])
        })
    }
    input.click()
  })
}
