// this exists purely to allow authenticating via
// google oauth and then open up the old ActiveAdmin/Vue
// interface until its entirely replaced.
import React, { useRef } from 'react'
import { makeStyles, Backdrop } from '@material-ui/core'
import Loading from 'components/loading'
import { useGoogleSession } from 'plugins/session'

const OLD_SITE_UI = process.env.REACT_APP_SHIPMENTS_URI || "https://shipments.ecogistics.net"

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  },
}))

export const Index = () => {
  const { token } = useGoogleSession()
  const styles = useStyles()
  const formRef = useRef(null)

  setTimeout(() => {
    formRef.current.submit()
  }, 500)

  return (
    <Backdrop open className={styles.backdrop}>
      <form ref={formRef} method="post" action={`${OLD_SITE_UI}/authenticate`}>
        <input type="hidden" name="token" value={token}/>
        <Loading title="redirecting you"/>
      </form>
    </Backdrop>
  )
}

export default Index
