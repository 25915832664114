import React, { useState } from 'react'
import { DialogTitle, DialogContent, DialogActions, Button, MenuItem } from '@material-ui/core'
import { makeValidate, TextField, Select, DatePicker } from 'mui-rff'
import DialogForm from 'components/dialog_form'
import MaterialTable from 'components/table'
import { useSnackbar } from 'notistack'
import * as yup from 'yup'

import FilterListIcon from '@material-ui/icons/FilterList'
import RestoreIcon from '@material-ui/icons/Restore'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'

import { DetailView } from './components'

import { useQuery, InvoiceCollections, InvoicesFollowUp, InvoicesReject, useMutation } from 'queries'

const FOLLOW_UP_REASONS = [
  "Invoice Not Received",
  "Missing Backup",
  "Invoice Not Approved",
  "Scheduled For Payment",
  "Invoice Paid",
  "Dispute",
  "Other",
]

const rejectSchema = yup.object().shape({
  who: yup.string().required(),
  happenedOn: yup.date().required(),
  notes: yup.string().required(),
})
const rejectValidator = makeValidate(rejectSchema)

const followUpSchema = yup.object().shape({
  who: yup.string().required(),
  reason: yup.string().required(),
  happenedOn: yup.date().required(),
  followUpOn: yup.date().required(),
})
const followUpValidator = makeValidate(followUpSchema)

const RejectForm = ({ onClose, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle>Invoice Rejected</DialogTitle>
      <DialogContent>
        <TextField name="invoices" label="Invoice" InputProps={{disabled: true}}/>
        <TextField name="who" label="Sender"/>
        <DatePicker name="happenedOn" label="Date" maxDate={new Date()}/>
        <TextField name="notes" label="Notes" multiline rows={2} rowsMax={4}/>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit}>Reject</Button>
      </DialogActions>
    </form>
  )
}

const FollowUpForm = ({ onClose, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle>Follow Up Note</DialogTitle>
      <DialogContent>
        <TextField name="invoices" label="Invoice" InputProps={{disabled: true}}/>
        <TextField name="who" label="Sender"/>
        <Select name="reason" label="Reason">
          { FOLLOW_UP_REASONS.map(v => <MenuItem key={v} value={v}>{v}</MenuItem>) }
        </Select>
        <DatePicker name="happenedOn" label="Date" maxDate={new Date()}/>
        <DatePicker name="followUpOn" label="Follow Up"/>
        <TextField name="notes" label="Notes" multiline rows={2} rowsMax={4}/>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit}>Follow Up</Button>
      </DialogActions>
    </form>
  )
}

const ReceiptDetail = ({ data }) => {
  return (
    <DetailView title="Customer" company={data.customer}>
      <MaterialTable
        data={data.receipts}
        columns={[
          { title: "Type", field: "type", shrink: true },
          { title: "Who", field: "who", shrink: true },
          { title: "Reason", field: "reason", shrink: true },
          { title: "Date", field: "happenedOn", defaultSort: 'desc', shrink: true },
          { title: "Follow Up", field: "followUpOn", shrink: true },
          { title: "Notes", field: "notes" },
        ]}
        options={{
          padding: "dense",
          paging: false,
          search: false,
          toolbar: false,
          headerStyle: { backgroundColor: "#efefef" },
        }}
        />
    </DetailView>
  )
}

export const Collections = () => {
  const [ displayFiltering, setFilterDisplay ] = useState(false)
  const { data, loading, refetch } = useQuery(InvoiceCollections, { notifyOnNetworkStatusChange: true })
  const [ addFollowUp ] = useMutation(InvoicesFollowUp)
  const [ rejectInvoice ] = useMutation(InvoicesReject)
  const [ invoices, setInvoiceNumbers ] = useState([])
  const [ dialogOpen, setDialogOpen ] = useState(false)
  const [ rejectDialogOpen, setRejectDialogOpen ] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const openDialog = (records) => {
    setInvoiceNumbers(records.map(v => v.invoiceNumber))
    setDialogOpen(true)
  }

  const openRejectDialog = (records) => {
    setInvoiceNumbers(records.map(v => v.invoiceNumber))
    setRejectDialogOpen(true)
  }

  const initialValues = { happenedOn: new Date(), invoices }

  const saveRejectReceipt = (data) => {
    setRejectDialogOpen(false)
    return rejectInvoice({ variables: { input: { ...data, reason: 'Rejected' }}})
      .then((res) => {
        enqueueSnackbar("Invoices Rejected", { variant: "success" })
        refetch()
        return res
      })
      .catch((err) => {
        enqueueSnackbar("Error Saving Follow Up", { variant: "error" })
      })
  }

  const saveReceipt = (data) => {
    setDialogOpen(false)
    return addFollowUp({ variables: { input: data }})
      .then((res) => {
        enqueueSnackbar("Follow Ups Saved", { variant: "success" })
        refetch()
        return res
      })
      .catch((err) => {
        enqueueSnackbar("Error Saving Follow Up", { variant: "error" })
      })
  }

  return (
    <>
      <DialogForm
        open={rejectDialogOpen}
        onClose={() => setRejectDialogOpen(false)}
        dialogProps={{ fullWidth: true, maxWidth: 'xs' }}
        onSubmit={saveRejectReceipt}
        validate={rejectValidator}
        initialValues={initialValues}
        component={RejectForm}
        />
      <DialogForm
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        dialogProps={{ fullWidth: true, maxWidth: 'xs' }}
        onSubmit={saveReceipt}
        validate={followUpValidator}
        initialValues={initialValues}
        component={FollowUpForm}
        />
      <MaterialTable
        title="Collections"
        data={data ? data.collections.nodes : []}
        refresh={refetch}
        isLoading={loading}
        columns={[
          { title: "Invoice", field: "invoiceNumber", shrink: true },
          { title: "Customer", field: "customer.name" },
          { title: "Status", field: "shipmentStatus", shrink: true },
          { title: "Amount", field: "due", type: 'currency', shrink: true },
          { title: "Delivery Date", field: "deliveredOn", shrink: true },
          { title: "Invoice Created", field: "invoicedOn", shrink: true },
          { title: "Invoice Sent", field: "invoiceSent", shrink: true },
          { title: "Invoice Method", field: "invoiceMethod", shrink: true },
          { title: "Last Contact", field: "lastContact", defaultSort: 'asc', shrink: true },
          { title: "Next Follow Up", field: "nextFollowUp", shrink: true },
          { title: "Note Type", field: "receipts.0.type", hidden: true, export: true },
          { title: "Contactee", field: "receipts.0.who", hidden: true, export: true },
          { title: "Reason", field: "receipts.0.reason", hidden: true, export: true },
          { title: "Note Date", field: "receipts.0.happenedOn", hidden: true, export: true },
          { title: "Follow Up", field: "receipts.0.followUpOn", hidden: true, export: true },
          { title: "Notes", field: "receipts.0.notes", hidden: true, export: true },
        ]}
        options={{
          exportButton: true,
          exportFileName: "collections",
          filtering: displayFiltering,
          grouping: true,
          selection: true,
          pageSize: 20,
        }}
        actions={[
          { icon: FilterListIcon, tooltip: "Filtering", isFreeAction: true, onClick: () => setFilterDisplay(!displayFiltering) },
          { icon: RestoreIcon, tooltop: "Reset Sent Invoice", onClick: (ev, data) => openRejectDialog(data) },
          { icon: QuestionAnswerIcon, tooltip: "Add Follow Up", onClick: (ev, data) => openDialog(data) },
        ]}
        detailPanel={(data) => <ReceiptDetail data={data}/>}
        />
    </>
  )
}
