import React from 'react'
import { ApolloProvider } from 'plugins/apollo'

import { BrowserRouter as Router, Route } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import { useQuery, SessionInfo } from 'queries'

import Header from './header'
import Footer from './footer'
import Blanket from 'components/blanket'
import Loading from 'components/loading'

import Accounting from 'pages/accounting'
import Carriers from 'pages/carriers'
import Dashboard from 'pages/dashboard'
import Imports from 'pages/imports'
import Migrate from 'pages/migrate'
import Timeclock from 'pages/timeclock'
import Users from 'pages/users'

export { default as Login } from './login'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}))

const sessionLoading = (<Loading title="Loading Session"/>)
const sessionError = (<Blanket logo={true} primary="Session Error" caption="Please speak to an administrator to resolve."/>)

const ApplicationRoot = () => {
  const style = useStyles()
  const { loading, error } = useQuery(SessionInfo)

  if (loading) { return sessionLoading }
  if (error) { return sessionError }

  return (
    <div className={style.root}>
      <Header/>
      <main className={style.content}>
        <div className={style.appBarSpacer} />
        <Container maxWidth="lg" className={style.container}>
          <AppRoutes/>
          <Footer/>
        </Container>
      </main>
    </div>
  )
}

const AppRoutes = () => {
  return (
    <React.Fragment>
      <Route exact path="/" component={Dashboard}/>

      {/* Activity */}
      {/* <Route path="/shipments" component={Shipments}/> */}
      {/* <Route path="/bookings" component={Bookings}/> */}

      {/* Logistics */}
      {/* <Route path="/logistics/capacity" component={Capacity}/> */}
      <Route path="/logistics/carriers" component={Carriers}/>
      {/* <Route path="/logistics/contact" component={Contact}/> */}
      {/* <Route path="/logistics/lanes" component={Lanes}/> */}

      {/* Admin / Accounting / Manage */}
      <Route path="/accounting" component={Accounting}/>
      <Route path="/manage/imports" component={Imports}/>
      {/* <Route path="/manage/reports" component={Reports}/> */}
      <Route path="/manage/timeclock" component={Timeclock}/>
      <Route path="/manage/users" component={Users}/>

      {/* Migrate/Redirect URL */}
      <Route path="/__/migrate/auth" component={Migrate}/>
    </React.Fragment>
  )
}

export const Layout = () => {
  return (
    <ApolloProvider>
      <Router>
        <ApplicationRoot/>
      </Router>
    </ApolloProvider>
  )
}
