import React from 'react'
import GoogleLogin from 'react-google-login'
import { Link, Paper } from '@material-ui/core'
import { makeStyles, Typography, Grid } from '@material-ui/core'
import HeaderLogo from 'images/header-logo.png'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100vw',
    height: '100vh',
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(6),
  },
  logo: {
    maxWidth: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    alignContent: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://ecogistics.org/">
        Ecogistics
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  )
}

function Login({ onSuccess, onFailure }) {
  const style = useStyles()

  return (
    <Grid container className={style.container} direction="column" justify="center" alignItems="center">
      <Grid item xs={3}>
        <Paper className={style.paper} align="center" elevation={4}>
          <img src={HeaderLogo} alt="logo" className={style.logo}/>

          <GoogleLogin
            className={style.submit}
            clientId="813819813502-trrouum8lts2ig1tk09k6ouklu7k4bjo.apps.googleusercontent.com"
            theme="dark"
            uxMode="popup"
            isSignedIn={true}
            onSuccess={onSuccess}
            onFailure={onFailure}
          />
          <br/>
          <Typography variant="caption">
            Authenticate using your corporate email address
          </Typography>
        </Paper>

        <Copyright/>
      </Grid>
    </Grid>
  )
}

export default Login
