import React from 'react'
import { Button } from '@material-ui/core'
import { SnackbarProvider as RealProvider } from 'notistack'

export { useSnackbar } from 'notistack'

export const SnackbarProvider = ({ children }) => {
  const snackbar = React.createRef()
  const onDismiss = (key) => () => snackbar.current.closeSnackbar(key)

  return (
    <RealProvider
      ref={snackbar}
      dense
      preventDuplicate
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={(key) => (
        <Button onClick={onDismiss(key)}>Dismiss</Button>
      )}
      children={children}
    />
  )
}
