import React from 'react'
import { Box, Tooltip, Typography, Link } from '@material-ui/core'

const REVISION = process.env.REACT_APP_REVISION || "DEVELOPMENT"

const Footer = () => (
  <Box pt={4}>
    <Tooltip title={REVISION} placement="top">
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" to="https://ecogistics.org/">
          Ecogistics
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Tooltip>
  </Box>
)

export default Footer
