import React, { createContext, useContext, useState, useEffect } from 'react'

const SessionContext = createContext({ isSignedIn: false })

export const SessionProvider = ({ children }) => {
  const [ session, setSessionState ] = useState({ isSignedIn: false })
  const [ googleUser, onAuthSuccess ] = useState()
  const [ authResponse, setAuthResponse] = useState()

  const setSession = (token, profile) => {
    const state = { isSignedIn: !!token, token, profile }
    sessionStorage.setItem('token', token)
    sessionStorage.setItem('profile', profile)
    setSessionState(state)
  }

  // pull auth response if user changes
  useEffect(
    () => {
      if (!googleUser) { return setSession(null, null) }
      setAuthResponse(googleUser.getAuthResponse())
    },
    [ googleUser ]
  )

  // ensure session & token stay up to date
  useEffect(
    () => {
      if (!authResponse) { return }
      setSession(authResponse.id_token, googleUser.profileObj)

      const refreshToken = async () => {
        setAuthResponse(await googleUser.reloadAuthResponse())
      }
      const refreshTime = (authResponse.expires_in || 3600) - 5 * 60
      console.log(`GoogleSession: Reloading Token in ${refreshTime / 60}min`)
      const timeoutId = setTimeout(refreshToken, refreshTime * 1000)
      return () => { clearTimeout(timeoutId) }
    },
    [ googleUser, authResponse ]
  )

  return (
    <SessionContext.Provider value={{
      onAuthSuccess,
      googleUser,
      authResponse,
      session
    }}>
      { children }
    </SessionContext.Provider>
  )
}

export const useGoogleSession = () => {
  const { onAuthSuccess, session } = useContext(SessionContext)

  return {
    onAuthSuccess,
    session,
    ...session,
  }
}
