import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Grid, Card, CardContent, Paper, Tab, Typography, makeStyles } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { useSnackbar } from 'notistack'

import Loading from 'components/loading'
import RemarkPopup from 'components/remarks'
import { useQuery, useMutation, CarrierView, CarrierNewRemark } from 'queries'

import { CarrierHistory, CarrierContacts, CarrierLanes } from './detail_tabs'
import RemarkList from './remarks'

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  list: {
    maxHeight: 400,
    overflow: "auto",
    width: "100%",
  },
  companyCard: {
    height: '12em',
  },
  notes: {
    minHeight: '11em',
  },
  noInfo: {
    marginTop: '2em',
  },
  panel: {
    padding: 0,
    paddingTop: '1px',
  }
}))

const CompanyCard = ({ type, name, phone, address, city, state, zipcode }) => {
  const style = useStyles()

  return (
    <Card className={style.companyCard}>
      { name ?
        <CardContent>
          <Typography variant="subtitle2" color="textSecondary" gutterBottom>{type}</Typography>
          <Typography variant="h6">{name}</Typography>
          <Typography variant="body2">
            {address}<br/>
            {city} {state}, {zipcode}<br/>
            {phone}
          </Typography>
        </CardContent>
        :
        <CardContent>
          <Typography variant="subtitle2" color="textSecondary" gutterBottom>{type}</Typography>
          <Typography variant="body1" align="center" className={style.noInfo}><em>No {type} Information</em></Typography>
        </CardContent>
      }
    </Card>
  )
}

export default function ViewCarrier() {
  const { id } = useParams()
  const style = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [ notesTab, setNotesTab ]= useState("0")
  const [ detailTab, setDetailTab ]= useState("0")
  const [ addRemark ] = useMutation(CarrierNewRemark)
  const { data, loading, refetch } = useQuery(CarrierView, { variables: { id }, notifyOnNetworkStatusChange: true })

  const saveRemark = (type) => ({ body }) => {
    return addRemark({ variables: { id, type, body }})
      .then((res) => {
        enqueueSnackbar("Saved Remark", { variant: "success" })
        refetch()
      })
      .catch((err) => {
        enqueueSnackbar("Failed to save Remark", { variant: "error" })
      })
  }

  if (loading) { return <Loading/> }
  const { factor, contacts, ...carrier } = data ? data.carrier : {factor: {},contacts:[]}

  return (
    <Grid container spacing={2}>
      <Grid item sm={8} container spacing={2} alignItems="stretch">
        <Grid item sm={6}>
          <CompanyCard type="Carrier" {...carrier}/>
        </Grid>
        <Grid item sm={6}>
          <CompanyCard type="Factor" {...factor}/>
        </Grid>
        <Grid item spacing={0} alignItems="stretch">
          <TabContext value={detailTab}>
            <TabList centered onChange={(_, idx) => setDetailTab(idx)}>
              <Tab label="Contacts" value="0"/>
              <Tab label="History" value="1"/>
              <Tab label="Lanes" value="2"/>
            </TabList>
            <TabPanel value="0" className={style.panel}>
              <CarrierContacts contacts={contacts}/>
            </TabPanel>
            <TabPanel value="1" className={style.panel}>
              <CarrierHistory carrierId={id}/>
            </TabPanel>
            <TabPanel value="2" className={style.panel}>
              <CarrierLanes/>
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>

      <Grid item sm={4}>
        <Paper className={style.notes}>
          <TabContext value={notesTab}>
            <TabList centered onChange={(_, idx) => setNotesTab(idx)}>
              <Tab label="Notes" value="0"/>
              <Tab label="Tasks" value="1"/>
            </TabList>
            <TabPanel value="0">
              <RemarkList remarks={carrier.notes}/>
              <RemarkPopup trigger={<Button color="primary">Add Note</Button>} onSubmit={saveRemark('NOTE')}/>
            </TabPanel>
            <TabPanel value="1">
              <RemarkList remarks={carrier.tasks}/>
              <RemarkPopup trigger={<Button color="primary">Add Task</Button>} onSubmit={saveRemark('TASK')}/>
            </TabPanel>
          </TabContext>
        </Paper>
      </Grid>

    </Grid>
  )
}
