import React from 'react'
import { Switch, Route } from "react-router-dom"

import { Outstanding } from './outstanding'
import { Receivables } from './receivables'
import { Collections } from './collections'

export default function Accounting() {
  return (
    <Switch>
      <Route exact path="/accounting" component={Index}/>
      <Route exact path="/accounting/outstanding" component={Outstanding}/>
      <Route exact path="/accounting/receivables" component={Receivables}/>
      <Route exact path="/accounting/collections" component={Collections}/>
    </Switch>
  )
}

const Index = () => (<></>)
