import React from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import { useConfirm } from 'material-ui-confirm'
import MaterialTable from 'components/table'
import { useQuery, ManageUsersAll } from 'queries'

import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'

export default function UserRoutes() {
  return (
    <Switch>
      <Route exact path="/manage/users" component={Index}/>
      {/* <Route exact path="/manage/users/:id" component={ViewUser}/> */}
    </Switch>
  )
}

const Index = () => {
  const { push } = useHistory()
  const confirm = useConfirm()
  const { data, loading, refetch } = useQuery(ManageUsersAll)

  const confirmDeletion = (user) => {
    confirm({ description: `Do you really want to remove ${user.fullName} from the system?` })
      .then(() => {
        console.log("Deleting", user)
      })
      .catch(() => {})
  }

  return (
    <MaterialTable
      title="Users"
      data={data ? data.users : []}
      refresh={refetch}
      isLoading={loading}
      columns={[
        { title: 'Name', field: 'fullName' },
        { title: 'Email', field: 'email' },
        { title: 'Leader', field: 'leader.fullName' },
        { title: 'Roles', field: 'roles', render: (data) => data.roles.join(", ") },
      ]}
      actions={[
        { icon: CreateIcon, tooltip: "Modify", onClick: (_, row) => push(`/manage/users/${row.id}`) },
        { icon: DeleteIcon, tooltip: "Delete User", onClick: (_, row) => confirmDeletion(row)  }
      ]}
      options={{
        paging: false,
      }}
      />
  )
}
