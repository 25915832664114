import React, { useState, useMemo } from 'react'
import { DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import { makeValidate, TextField } from 'mui-rff'
import * as yup from 'yup'

import DialogForm from './dialog_form'

const schema = yup.object().shape({
  body: yup.string().required(),
})
const validator = makeValidate(schema)


const RemarkForm = ({ handleSubmit, onClose }) => {
  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle>Add Remark</DialogTitle>
      <DialogContent>
        <TextField name="body" label="Content" autoFocus={true}/>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit}>Save</Button>
      </DialogActions>
    </form>
  )
}

export default function RemarkPopup({ trigger, onSubmit }) {
  const [ open, setOpen ] = useState(false)
  const realTrigger = useMemo(() => (
    React.cloneElement(trigger, { onClick: () => setOpen(true) })
  ), [trigger])

  const handleSubmit = (data) => {
    return onSubmit(data).then(() => {
      setOpen(false)
      return data
    })
  }

  return (
    <React.Fragment>
      {realTrigger}
      <DialogForm
        open={open}
        onClose={() => setOpen(false)}
        dialogProps={{ fullWidth: true, maxWidth: 'xs' }}
        onSubmit={handleSubmit}
        validate={validator}
        component={RemarkForm}
        />
    </React.Fragment>
  )
}
