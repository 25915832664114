import React from 'react'
import ReactDOM from 'react-dom'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ConfirmProvider } from 'material-ui-confirm'
import { SessionProvider } from 'plugins/session'
import { SnackbarProvider } from 'plugins/snackbar'

import App from 'App'
import * as serviceWorker from 'serviceWorker'

ReactDOM.render(
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <SnackbarProvider>
      <ConfirmProvider>
        <SessionProvider>
          <CssBaseline/>
          <App/>
        </SessionProvider>
      </ConfirmProvider>
    </SnackbarProvider>
  </MuiPickersUtilsProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
