import { loader } from 'graphql.macro'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { getFileMetadata, directUpload } from 'plugins/storage'

export { useQuery, useLazyQuery, useMutation }

// Queries
export const BookingsIndex = loader("./bookings_index.gql")
export const CarriersIndex = loader("./carriers_index.gql")
export const CarrierHistoricals = loader("./carrier_historicals.gql")
export const CarrierView = loader("./carrier_view.gql")
export const InvoiceOutstanding = loader('./invoice_outstanding.gql')
export const InvoiceReceivables = loader('./invoice_receivables.gql')
export const InvoiceCollections = loader('./invoice_collections.gql')
export const ManageUsersAll = loader("./manage_users_all.gql")
export const SessionInfo = loader("./session_info.gql")
export const ShipmentDetails = loader("./shipment_details.gql")
export const ShipmentIndex = loader("./shipment_index.gql")
export const TimeclockEntries = loader("./timeclock_entries.gql")
export const UserRoles = loader("./user_roles.gql")

// Mutations
export const CarrierNewRemark = loader("./carrier_new_remark.gql")
export const CreateUpload = loader("./create_upload.gql")
export const ImportFile = loader('./import.gql')
export const InvoicesNote = loader('./invoices_note.gql')
export const InvoicesFollowUp = loader('./invoices_follow_up.gql')
export const InvoicesReject = loader('./invoices_reject.gql')
export const InvoicesSentReceipt = loader('./invoices_sent_receipt.gql')
export const ToggleRemark = loader('./toggle_remark.gql')
export const UpdateTimeclockEntry = loader("./update_timeclock_entry.gql")

export const useDirectUpload = (query, params) => {
  const [createUpload] = useMutation(CreateUpload)
  const [submitQuery] = useMutation(query)

  const upload = (file) => {
    return getFileMetadata(file)
      .then(input => createUpload({ variables: { input } }))
      .then(({ data: { createDirectUpload: { url, headers, signedBlobId } }}) => {
        return directUpload(url, JSON.parse(headers), file).then(() => {
          return submitQuery({ variables: { ...params, signedBlobId }})
        })
      })
  }

  return upload
}
