import React from 'react'
import { Chip } from '@material-ui/core'
import MaterialTable from 'components/table'
import { useQuery, CarrierHistoricals } from 'queries'

const CHIP_COLORS = {
  "Complaint": "red",
  "Invalid": "red",
  "Placeholder": "orange",
  "Unknown": "orange",
  "Bounce": "red",
  "Unsubscribed": "orange",
  "Subscribed": "green",
}

const chipColor = (reason) => (CHIP_COLORS[reason] || "primary")

const SubscriptionChip = ({ status }) => {
  const color = chipColor(status)
  return (
    <Chip
      variant="outlined"
      size="small"
      label={status}
      style={{ color, borderColor: color }}/>
  )
}

export const CarrierContacts = ({ contacts }) => {
  return (
    <MaterialTable
      title="Contacts"
      options={{ pageSize: 10 }}
      data={contacts || []}
      columns={[
        { title: "Name", field: "name" },
        { title: "Phone", field: "phone", shrink: true },
        { title: "Email",
          render: rowData => <a href={`mailto:${rowData.email}`}>{rowData.email}</a>,
        },
        { title: "Mailer Status", shrink: true,
          render: rowData => <SubscriptionChip status={rowData.subscriptionStatus}/>,
        },
      ]}
      />
  )
}

export const CarrierHistory = ({ carrierId }) => {
  const { data, loading, refetch } = useQuery(CarrierHistoricals, {
    variables: { id: carrierId },
    notifyOnNetworkStatusChange: true
  })

  return (
    <MaterialTable
      title="Past Loads"
      options={{ pageSize: 10 }}
      data={data ? data.carrier.historicals : []}
      refresh={refetch}
      isLoading={loading}
      columns={[
        // { title: "Load", field: "load", shrink: true },
        { title: "Customer", field: "customer.name" },
        { title: "Status", field: "shipmentStatus", shrink: true },
        { title: "Delivered", field: "deliveredOn", shrink: true },
        { title: "Cost", field: "cost", type: "currency", shrink: true },
        { title: "Dispatcher", field: "dispatcher" },
      ]}
      />
  )
}

export const CarrierLanes = () => {
  return (
    <MaterialTable
      title="Lanes"
      refresh={() => {}}
      columns={[
        { title: "Origin", field: "" },
        { title: "Destination", field: "" },
        { title: "Equipment", field: "", shrink: true },
        { title: "Frequency", field: "", shrink: true },
        { title: "Last Run", field: "", shrink: true },
        { title: "Last Rate", field: "", shrink: true },
      ]}
      options={{ pageSize: 5 }}
      localization={{
        body: { emptyDataSourceMessage: <em>Not Implemented Yet.</em> }
      }}
      />
  )
}
