import React from 'react'
import MaterialTable from 'components/table'

import { useQuery, InvoiceOutstanding } from 'queries'

export const Outstanding = () => {
  const { data, loading, refetch } = useQuery(InvoiceOutstanding, { notifyOnNetworkStatusChange: true })

  return (
    <MaterialTable
      title="Outstanding Deliveries"
      data={data ? data.outstanding.nodes : []}
      refresh={refetch}
      isLoading={loading}
      columns={[
        { title: "Load", field: "load", shrink: true },
        { title: "Customer", field: "customer.name" },
        { title: "Carrier", field: "carrier.name" },
        { title: "Delivery Date", field: "deliveredOn", shrink: true },
        { title: "Status", field: "shipmentStatus", shrink: true },
      ]}
      options={{
        pageSize: 20,
      }}
      />
  )
}
