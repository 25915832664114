import React from 'react'
import { format, parseISO } from 'date-fns'
import { Avatar, Checkbox, Tooltip, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction } from '@material-ui/core'
import { ToggleRemark, useMutation } from 'queries'

export default function RemarkList({ remarks = [] }) {
  return (
    <List>
      { remarks.map(remark => <Remark key={remark.id} item={remark}/>) }
    </List>
  )
}

const Remark = ({ item: { user, ...remark } }) => {
  const [ toggleRemark ] = useMutation(ToggleRemark)
  const date = format(parseISO(remark.createdAt), 'yyyy-MM-dd')

  const flipStatus = () => {
    toggleRemark({ variables: { id: remark.id, value: !remark.completed }})
  }

  return (
    <ListItem>
      <ListItemAvatar>
        <Tooltip title={user.fullName} placement="top-end">
          <Avatar alt={user.fullName}>{user.initials}</Avatar>
        </Tooltip>
      </ListItemAvatar>
      <ListItemText primary={date} secondary={remark.body}/>
      <ListItemSecondaryAction>
        <Checkbox edge="end" checked={remark.completed} onClick={flipStatus}/>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
