import React from 'react'
import { Card, Grid, Typography, CardContent, makeStyles, List, ListItem, ListItemText, Divider } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  grid: {
    padding: '1em 5em',
  },
  card: {
    backgroundColor: '#efefef',
  },
  title: {
    fontSize: 14,
  },
  list: {
    maxHeight: 300,
    overflow: "auto",
    width: "100%",
  },
}))

export const DetailView = ({ title, company, children }) => {
  const styles = useStyles()

  return (
    <Grid container spacing={2} className={styles.grid}>
      <Grid item xs={3}>
        <CompanyCard title={title} {...company}/>
      </Grid>
      <Grid item xs>
        {children}
      </Grid>
    </Grid>
  )
}

export const CompanyCard = ({ title, name, phone, factor, contacts = [] }) => {
  const style = useStyles()

  return (
    <>
      <Card className={style.card}>
        <CardContent>
          <Typography className={style.title} color="textSecondary" gutterBottom>
            {title}
          </Typography>
          <Typography variant="subtitle1">{name}</Typography>
          <ContactList phone={phone} contacts={contacts}/>
        </CardContent>
      </Card>
      { factor && <br/> }
      { factor &&
        <Card className={style.card}>
          <CardContent>
            <Typography className={style.title} color="textSecondary" gutterBottom>
              Factor
            </Typography>
            <Typography variant="subtitle1">{factor.name}</Typography>
            <ContactList phone={factor.phone}/>
          </CardContent>
        </Card>
      }
    </>
  )
}

const ContactList = ({ phone, contacts = [] }) => {
  const style = useStyles()

  return (
    <List className={style.list}>
      <ListItem>
        <ListItemText primary="Corporate" secondary={phone || "N/A"}/>
      </ListItem>
      { contacts.length > 0 && <Divider/> }
      { contacts.map(({ name, phone }) => (
        <ListItem>
          <ListItemText primary={name} secondary={phone}/>
        </ListItem>
      ))}
    </List>
  )
}
