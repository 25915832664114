import React from 'react'
import clsx from 'clsx'
import { useGoogleLogout } from 'react-google-login'
import { makeStyles, AppBar, Drawer, Toolbar, Tooltip, IconButton, Typography } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import { useGoogleSession } from 'plugins/session'
import NavigationMenu from './menu'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  menuButton: {
    marginRight: 36,
  },
  logoutButton: {
    marginLeft: 18,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8),
    },
  },

}))

const Header = () => {
  const style = useStyles()
  const [open, setOpen] = React.useState(true)
  const openDrawer = () => { setOpen(true) }
  const closeDrawer = () => { setOpen(false) }
  const { profile } = useGoogleSession()
  const { signOut } = useGoogleLogout({
    clientId: "813819813502-trrouum8lts2ig1tk09k6ouklu7k4bjo.apps.googleusercontent.com",
    onLogoutSuccess: () => { window.location = window.location.origin },
    onFailure: (err) => console.log("Logout Failure", err)
  })

  return (
    <React.Fragment>
      <AppBar position="absolute" className={clsx(style.appBar, open && style.appBarShift)}>
        <Toolbar className={style.toolbar}>
          <IconButton edge="start" color="inherit" onClick={openDrawer}
            className={clsx(style.menuButton, open && style.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={style.title}>
            Moneyboard
          </Typography>
          <Typography component="h2" variant="subtitle2" color="inherit" noWrap>
            { profile.name }
          </Typography>
          <Tooltip title="Logout" className={style.logoutButton}>
            <IconButton edge="end" color="inherit" onClick={signOut}>
              <ExitToAppIcon/>
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open} classes={{ paper: clsx(style.drawerPaper, !open && style.drawerPaperClose) }}>
        <div className={style.toolbarIcon}>
          <IconButton onClick={closeDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <NavigationMenu detail={open}/>
      </Drawer>
    </React.Fragment>
  )
}

export default Header
