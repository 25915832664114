import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Grid, Typography, LinearProgress, Paper } from '@material-ui/core'

import HeaderLogo from 'images/header-logo.png'

const useStyle = makeStyles(theme => ({
  container: {
    width: '100vw',
    height: '100vh',
  },
  text: {
    textTransform: 'uppercase',
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    '& img': {
      maxWidth: '100%',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      alignContent: 'center',
    }
  }
}))

export const Blanket = ({ logo, progress, primary, caption }) => {
  const style = useStyle()

  return (
    <Grid container className={style.container} justify="center" alignItems="center">
        <Grid item xs={3}>
          <Paper className={style.paper}>
            { logo && <img src={HeaderLogo} alt="Company Logo"/> }
            <Typography variant="h5" align="center" className={style.text}>{primary}</Typography>
            { caption && <Typography variant="caption" align="center">{caption}</Typography> }
            { progress && <LinearProgress /> }
          </Paper>
      </Grid>
    </Grid>
  )
}

Blanket.propTypes = {
  logo: PropTypes.bool,
  progress: PropTypes.bool,
  primary: PropTypes.string.isRequired,
  caption: PropTypes.string,
}

export default Blanket
