import React from 'react'
import { makeStyles, Card, CardContent, Typography, Button, Grid } from '@material-ui/core'

import CloudUploadIcon from '@material-ui/icons/CloudUpload'

import { useFileImport } from 'components/import'

const IMPORTS = [
  { icon: null, title: "Carriers", importer: "Carriers", from: "Brokerware" },
  { icon: null, title: "Carrier Contacts", importer: "CarrierContacts", from: "Brokerware" },
  { icon: null, title: "Customers", importer: "Customers", from: "Brokerware" },
  { icon: null, title: "Historic Transactions", importer: "Transactions", from: "Brokerware" },
  { icon: null, title: "Invoicing Data", importer: "Invoices", from: "Brokerware" },
  { icon: null, title: "Timeclock Entries", importer: "TimeclockEntries", from: "Timeclock" },
  { icon: null, title: "Capacity", importer: "Capacities", template: true },
  { icon: null, title: "Carrier Additions", importer: "CarrierAdditions", template: true },
]

const useStyles = makeStyles(theme => ({
  spacer: {
    marginBottom: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}))

const TemplateLine = ({ file }) => {
  return (
    <Typography display="block" align="center" variant="caption">
      <a href="/api/v1/templates/{file}">template</a>, fill in and import
    </Typography>
  )
}

const ImportCard = ({ icon, title, file, importer, from, template }) => {
  const style = useStyles()
  const upload = useFileImport(importer, { snackbar: true })

  return (
    <Grid item md={4}>
      <Card>
        <CardContent>
          <Typography variant="h6" className={style.spacer}>
            { icon }
            { title }
          </Typography>
          <Button fullWidth className={style.spacer} variant="outlined" color="primary" startIcon={<CloudUploadIcon/>} onClick={upload}>Import</Button>
          { from && <Typography align="center" display="block" variant="caption">Upload directly from { from }</Typography> }
          { template && <TemplateLine file={file}/> }
        </CardContent>
      </Card>
    </Grid>
  )
}

const Index = () => {
  const style = useStyles()

  return (
    <React.Fragment>
      <Typography variant="h6" color="primary" className={style.spacer}>Imports</Typography>
      <Grid container justify="center" spacing={4}>
        { IMPORTS.map((props, idx) => <ImportCard key={idx} {...props}/>) }
      </Grid>
    </React.Fragment>
  )
}

export default Index
