import React from 'react'
import { Dialog } from '@material-ui/core'
import { Form } from 'react-final-form'

export const DialogForm = ({open, onClose, onSubmit, validate, initialValues, component, dialogProps, ...props}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      {...dialogProps}>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
        component={component}
        onClose={onClose}
        {...props}
        />
    </Dialog>
  )
}

export default DialogForm
