import React, { forwardRef, useMemo } from 'react'
import { Typography } from '@material-ui/core'
import RawMaterialTable from 'material-table'
import { CsvBuilder } from 'filefy'
import jsPDF from "jspdf"
import "jspdf-autotable"

import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import RefreshIcon from '@material-ui/icons/Refresh'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

// // this is so we can export hidden columns by default material-table will not.
// // https://github.com/mbrn/material-table/blob/master/src/components/m-table-toolbar.js#L35
const byString = (o, s) => {
  if (!s) {
    return;
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var x = a[i];
    if (o && x in o) {
      o = o[x];
    } else {
      return;
    }
  }
  return o;
};

const massageExportData = (columns, data) => {
  columns = columns
    .filter(columnDef => {
      return columnDef.field && (columnDef.export === true || (!columnDef.hidden && columnDef.export !== false));
    })
    .sort((a, b) => (a.tableData.columnOrder > b.tableData.columnOrder) ? 1 : -1)

  data = data.map(rowData =>
    columns.map(columnDef => {
      return byString(rowData, columnDef.field)
    })
  )

  return [columns, data]
}

const exportCsv = (filename) => (columns, data) => {
  [columns, data] = massageExportData(columns, data)

  const builder = new CsvBuilder(filename + '.csv')
  builder
    .setColumns(columns.map(columnDef => columnDef.title))
    .addRows(data)
    .exportFile();
}

const exportPdf = (title, filename) => (columns, data) => {
  [columns, data] = massageExportData(columns, data)

  let content = {
    startY: 50,
    head: [columns.map((columnDef) => columnDef.title)],
    body: data,
  };

  const unit = "pt"
  const size = "A4"
  const orientation = "landscape"

  const doc = new jsPDF(orientation, unit, size)
  doc.setFontSize(12)
  doc.text(title, 40, 40)
  doc.autoTable(content)
  doc.save(filename + ".pdf")
};

const columnExtensions = (columns) => columns.map(col => {
  if (col.hidden) { col.shrink = true }
  if (!col.shrink) { return col }
  delete col.shrink

  const cellStyle = col.cellStyle || {}
  const headerStyle = col.headerStyle || {}
  return {
    width: '1%',
    ...col,
    cellStyle: { whiteSpace: 'nowrap', ...cellStyle },
    headerStyle: { whiteSpace: 'nowrap', ...headerStyle },
  }
})

const MaterialTable = ({ title, columns, options = {}, actions = [], refresh, data, ...props }) => {

  options.exportCsv = exportCsv(options.exportFileName || "data")
  options.exportPdf = exportPdf(title, options.exportFileName || "data")

  // we do this cause material-table wants to alter the data
  const tableData = useMemo(() => data && data.map(o => ({ ...o })), [ data ])

  const extraActions = []
  if (refresh) {
    extraActions.push({
      icon: RefreshIcon, tooltip: "Refresh", isFreeAction: true, onClick: () => refresh()
    })
  }

  return (
    <RawMaterialTable
      icons={tableIcons}
      options={options}
      title={<Typography variant="h6" color="primary">{title}</Typography>}
      columns={columnExtensions(columns)}
      data={tableData}
      actions={[ ...actions, ...extraActions ]}
      {...props}
      />
  )
}

export default MaterialTable
