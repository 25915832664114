import React, { useState, useMemo } from 'react'
import { DialogTitle, DialogContent, DialogActions, Button, MenuItem, TextField as MUIField } from '@material-ui/core'
import { makeValidate, TextField, Select, DatePicker } from 'mui-rff'
import DialogForm from 'components/dialog_form'
import MaterialTable from 'components/table'
import { useSnackbar } from 'notistack'
import * as yup from 'yup'

import FilterListIcon from '@material-ui/icons/FilterList'
import MailIcon from '@material-ui/icons/Mail'
import SmsFailedIcon from '@material-ui/icons/SmsFailed'
import AssignmentIcon from '@material-ui/icons/Assignment'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'

import { useRoles } from 'components/auth'
import { DetailView } from './components'

import { useQuery, InvoiceReceivables, InvoicesNote, InvoicesSentReceipt, useMutation } from 'queries'

const NOTE_REASONS = [
  "No BOL",
  "Incorrect BOL",
  "Incomplete BOL",
  "Illegible BOL",
  "No Consignee Signature",
  "Submitted for Audit",
  "Processed by Audit",
  "Other",
]

const SEND_METHODS = [
  "Carrierpoint",
  "Cass Upload",
  "Email",
  "Manhattan Associates",
  "Navisphere",
  "Nutrien Freight",
  "TMS Attachment",
  "Tranzact",
]

const schema = yup.object().shape({
  who: yup.string().required(),
  reason: yup.string().required(),
  happenedOn: yup.date().required(),
})
const validator = makeValidate(schema)

const InvoiceNoteForm = ({ onClose, handleSubmit, invoices }) => {
  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle>Invoice Note</DialogTitle>
      <DialogContent>
        <MUIField fullWidth label="Invoices" value={`Updating ${invoices.length} Selected Invoices`} InputProps={{ disabled: true }} />
        <input type="hidden" name="invoices" />
        <TextField name="who" label="Who" />
        <Select name="reason" label="Reason">
          {NOTE_REASONS.map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)}
        </Select>
        <DatePicker name="happenedOn" label="Date" maxDate={new Date()} />
        <TextField name="notes" label="Notes" multiline rows={2} rowsMax={4} />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit}>Save Note</Button>
      </DialogActions>
    </form>
  )
}

const InvoiceSentForm = ({ onClose, handleSubmit, invoices }) => {
  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle>Invoice Sent Receipt</DialogTitle>
      <DialogContent>
        <MUIField fullWidth label="Invoices" value={`Updating ${invoices.length} Selected Invoices`} InputProps={{ disabled: true }} />
        <input type="hidden" name="invoices" />
        <TextField name="who" label="Who" />
        <Select name="reason" label="Method">
          {SEND_METHODS.map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)}
        </Select>
        <DatePicker name="happenedOn" label="Date" maxDate={new Date()} />
        <TextField name="notes" label="Notes" multiline rows={2} rowsMax={4} />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit}>Mark Sent</Button>
      </DialogActions>
    </form>
  )
}

const ReceiptDetail = ({ data }) => {
  return (
    <DetailView title="Carrier" company={data.carrier}>
      <MaterialTable
        data={data.notes}
        columns={[
          { title: "Who", field: "who", shrink: true },
          { title: "Reason", field: "reason", shrink: true },
          { title: "Date", field: "happenedOn", defaultSort: 'desc', shrink: true },
          { title: "Notes", field: "notes" },
        ]}
        options={{
          padding: "dense",
          paging: false,
          search: false,
          toolbar: false,
          headerStyle: { backgroundColor: "#efefef" },
        }}
      />
    </DetailView>
  )
}

export const Receivables = () => {
  const [displayFiltering, setFilterDisplay] = useState(false)
  const { data, loading, refetch } = useQuery(InvoiceReceivables, { notifyOnNetworkStatusChange: true })
  const [hideTurnedIn, setTurnedIn] = useState(false)
  const [sendInvoices] = useMutation(InvoicesSentReceipt)
  const [saveNote] = useMutation(InvoicesNote)
  const [invoices, setInvoiceNumbers] = useState([])
  const { enqueueSnackbar } = useSnackbar()
  const roles = useRoles()

  const [sentDialogOpen, setSentDialogOpen] = useState(false)
  const [noteDialogOpen, setNoteDialogOpen] = useState(false)

  const initialValues = { happenedOn: new Date(), invoices }

  const openSentDialog = (records) => {
    setInvoiceNumbers(records.map(v => v.invoiceNumber))
    setSentDialogOpen(true)
  }

  const openNoteDialog = (records) => {
    setInvoiceNumbers(records.map(v => v.invoiceNumber))
    setNoteDialogOpen(true)
  }

  const saveNoteReceipt = (data) => {
    setNoteDialogOpen(false)
    return saveNote({ variables: { input: data } })
      .then((res) => {
        enqueueSnackbar("Invoice Notes Saved", { variant: "success" })
        refetch()
        return res
      })
      .catch((err) => {
        enqueueSnackbar("Error Saving Notes", { variant: "error" })
      })
  }

  const saveSentReceipt = (data) => {
    setSentDialogOpen(false)
    return sendInvoices({ variables: { input: data } })
      .then((res) => {
        enqueueSnackbar("Invoices Marked as Sent", { variant: "success" })
        refetch()
        return res
      })
      .catch((err) => {
        enqueueSnackbar("Error Marking Invoices", { variant: "error" })
      })
  }

  const additionalActions = useMemo(() => {
    if (roles.includes("collections")) {
      return [
        { icon: MailIcon, tooltip: "Mark Sent", onClick: (ev, data) => openSentDialog(data) },
      ]
    }
    return []
  }, [roles])

  const AssignIcon = hideTurnedIn ? AssignmentTurnedInIcon : AssignmentIcon;
  const dataset = useMemo(() => {
    if (!data) { return [] }
    if (!hideTurnedIn) { return data.receivables.nodes }

    return data.receivables.nodes.filter(r => !r.proofOfDelivery)
  }, [hideTurnedIn, data])

  return (
    <>
      <DialogForm
        open={noteDialogOpen}
        onClose={() => setNoteDialogOpen(false)}
        dialogProps={{ fullWidth: true, maxWidth: 'xs' }}
        onSubmit={saveNoteReceipt}
        validate={validator}
        initialValues={initialValues}
        component={InvoiceNoteForm}
        invoices={invoices}
      />
      <DialogForm
        open={sentDialogOpen}
        onClose={() => setSentDialogOpen(false)}
        dialogProps={{ fullWidth: true, maxWidth: 'xs' }}
        onSubmit={saveSentReceipt}
        validate={validator}
        initialValues={initialValues}
        component={InvoiceSentForm}
        invoices={invoices}
      />
      <MaterialTable
        title="Receivables"
        data={dataset}
        refresh={refetch}
        isLoading={loading}
        columns={[
          { title: "Invoice", field: "invoiceNumber", shrink: true },
          { title: "Customer", field: "customer.name" },
          { title: "Carrier", field: "carrier.name" },
          { title: "Factor", field: "carrier.factor.name" },
          { title: "Delivery Date", field: "deliveredOn", shrink: true },
          { title: "Invoice Created", field: "invoicedOn", shrink: true },
          { title: "PRO#", field: "proNumber", shrink: true },
          {
            title: "POD", field: "", shrink: true, render: (row) => {
              return row.proofOfDelivery ? "True" : ""
            }
          },
          { title: "Dispatcher", field: "dispatcher", shrink: true },
          { title: "Contactee", field: "notes.0.who", hidden: true, export: true },
          { title: "Note Date", field: "notes.0.happenedOn", hidden: true, export: true },
          { title: "Reason", field: "notes.0.reason", hidden: true, export: true },
          { title: "Notes", field: "notes.0.notes", hidden: true, export: true },
        ]}
        options={{
          exportButton: true,
          exportFileName: "receivables",
          filtering: displayFiltering,
          selection: true,
          grouping: true,
          pageSize: 20,
        }}
        actions={[
          { icon: AssignIcon, tooltip: (hideTurnedIn ? "Show BOL Sent to Accounting" : "Hide BOL Sent to Accounting"), isFreeAction: true, onClick: () => setTurnedIn(!hideTurnedIn) },
          { icon: FilterListIcon, tooltip: "Filtering", isFreeAction: true, onClick: () => setFilterDisplay(!displayFiltering) },
          { icon: SmsFailedIcon, tooltip: "Add Note", onClick: (ev, data) => openNoteDialog(data) },
          ...additionalActions,
        ]}
        detailPanel={(data) => <ReceiptDetail data={data} />}
      />
    </>
  )
}
