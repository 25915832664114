import React from 'react'
import { Link as RouterLink, NavLink as RouterNavLink } from 'react-router-dom'
import { Button as MaterialButton, Link as MaterialLink } from '@material-ui/core'
import { ListItem } from '@material-ui/core'

// this is to wrap material-ui components in
// react-router so page linking works properly
const wrapComponent = (Component, InnerComponent) => ({ children, ...props }) => {
  return (
    <Component component={InnerComponent} {...props}>
      {children}
    </Component>
  )
}

export const Button = wrapComponent(MaterialButton, RouterLink)
export const Link = wrapComponent(MaterialLink, RouterLink)
export const NavLink = wrapComponent(MaterialLink, RouterNavLink)
export const ListItemLink = wrapComponent(ListItem, RouterLink)
export const ListItemNavLink = wrapComponent(ListItem, RouterNavLink)
