import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Tooltip, Divider, ListItemIcon, ListItemText, Typography } from '@material-ui/core'

// import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
// import AssessmentIcon from '@material-ui/icons/Assessment'
// import BookIcon from '@material-ui/icons/Book'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
// import ContactMailIcon from '@material-ui/icons/ContactMail'
import ContactsIcon from '@material-ui/icons/Contacts'
// import ExploreIcon from '@material-ui/icons/Explore'
import FlipToBackIcon from '@material-ui/icons/FlipToBack'
import GavelIcon from '@material-ui/icons/Gavel'
import InboxIcon from '@material-ui/icons/Inbox'
import LinkIcon from '@material-ui/icons/Link'
// import LocalShippingIcon from '@material-ui/icons/LocalShipping'
// import MapIcon from '@material-ui/icons/Map'
import PeopleIcon from '@material-ui/icons/People'
import ReceiptIcon from '@material-ui/icons/Receipt'
import ScheduleIcon from '@material-ui/icons/Schedule'

import { IsAdmin, IsAccounting, HasRole } from 'components/auth'
import { ListItemNavLink } from 'components/links'

const useStyles = makeStyles(theme => ({
  link: {
    '&.active': {
      color: theme.palette.primary.main,
    },
  },
  icon: {
    color: 'currentColor',
  },
  dividerHeader: {
    margin: `5px 0 0 ${theme.spacing(2)}px`,
  }
}))

const MenuItem = ({ to, icon, primary }) => {
  const style = useStyles()

  return (
    <ListItemNavLink className={style.link} button to={to}>
      <Tooltip title={primary} placement="right">
        <ListItemIcon className={style.icon}>
          {icon}
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary={primary}/>
    </ListItemNavLink>
  )
}

const NavigationHeader = ({ visible, title }) => {
  const style = useStyles()

  return (
    <Typography
      className={style.dividerHeader}
      color="textSecondary"
      display="block"
      variant="caption">
      &nbsp;{visible && title}
    </Typography>
  )
}

const NavigationMenu = ({ detail }) => {
  return (
    <div>
      {/* <NavigationHeader visible={detail} title="Activity"/> */}
      {/* <MenuItem icon={<LocalShippingIcon/>} primary="Shipments" to="/shipments"/> */}
      {/* <MenuItem icon={<BookIcon/>} primary="Bookings" to="/bookings"/> */}

      {/* <Divider/> */}
      <NavigationHeader visible={detail} title="Logistics"/>
      {/* <MenuItem icon={<ContactMailIcon/>} primary="Mailer" to="/logistics/mailer"/> */}
      {/* <MenuItem icon={<ExploreIcon/>} primary="Lanes" to="/logistics/lanes"/> */}
      {/* <MenuItem icon={<MapIcon/>} primary="Capacity" to="/logistics/capacity"/> */}
      <MenuItem icon={<ContactsIcon/>} primary="Carriers" to="/logistics/carriers"/>

      <IsAccounting>
        <Divider/>
        <NavigationHeader visible={detail} title="Accounting"/>
        <MenuItem icon={<InboxIcon/>} primary="Outstanding" to="/accounting/outstanding"/>
        <HasRole role="receivables">
          <MenuItem icon={<ReceiptIcon/>} primary="Receivables" to="/accounting/receivables"/>
        </HasRole>
        <HasRole role="collections">
          <MenuItem icon={<GavelIcon/>} primary="Collections" to="/accounting/collections"/>
        </HasRole>
      </IsAccounting>

      <IsAdmin>
        <Divider/>
        <NavigationHeader visible={detail} title="Manage"/>
        {/* <MenuItem icon={<AccountBalanceIcon/>} primary="Goals" to="/manage/goals"/> */}
        <MenuItem icon={<CloudUploadIcon/>} primary="Imports" to="/manage/imports"/>
        {/* <MenuItem icon={<AssessmentIcon/>} primary="Reports" to="/manage/reports"/> */}
        <MenuItem icon={<ScheduleIcon/>} primary="Timeclock" to="/manage/timeclock"/>
        <MenuItem icon={<PeopleIcon/>} primary="Users" to="/manage/users"/>
     </IsAdmin>

      <Divider/>
      <MenuItem icon={<FlipToBackIcon/>} primary="Old LoadBoard" to="/__/migrate/auth"/>
      <MenuItem icon={<LinkIcon/>} primary="Bookmarks" to="/links"/>
    </div>
  )
}

NavigationMenu.propTypes = {
  detail: PropTypes.bool,
}

NavigationMenu.defaultProps = {
  detail: false,
}

export default NavigationMenu
