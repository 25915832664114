import React from 'react'
import { Layout, Login } from 'layout'
import { useGoogleSession } from 'plugins/session'

import { useSnackbar } from 'plugins/snackbar'

function App() {
  const { enqueueSnackbar } = useSnackbar()
  const { onAuthSuccess, isSignedIn } = useGoogleSession()

  const authFailure = (res) => {
    const { error, details } = res


    enqueueSnackbar(`Login Error: ${error} ${details || ''}`, {
      variant: 'error',
      autoHideDuration: 10000,
    })
    console.dir({ msg: "AUTH:FAIL", payload: res })
  }

  if (!isSignedIn) { return <Login onSuccess={onAuthSuccess} onFailure={authFailure}/> }
  return <Layout/>
}

export default App
