import React, { useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { format } from 'date-fns'

import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import TodayIcon from '@material-ui/icons/Today'

import MaterialTable from 'components/table'
import { useLazyQuery, useMutation, TimeclockEntries, UpdateTimeclockEntry } from 'queries'

import { useFileImport } from 'components/import'

const useStyles = makeStyles(_ => ({
  datePicker: {
    display: 'none',
  }
}))

const updatePick = ({ excluded, notes }) => ({ excluded, notes })

const Index = () => {
  const style = useStyles()
  const [ date, setDate ] = useState(new Date())
  const [ pickerOpen, setPickerOpen ] = useState(false)
  const [ loadEntries, { data, loading } ] = useLazyQuery(TimeclockEntries)
  const [ updateEntry ] = useMutation(UpdateTimeclockEntry)
  const importFile = useFileImport("TimeclockEntries", { snackbar: true })

  const selectDate = (date) => {
    setDate(date)
    loadEntries({ variables: { date } })
  }
  const title = useMemo(() => {
    const suffix = date ? `(${format(date, "yyyy-MM-dd")})` : ''
    return `Timeclock Entries ${suffix}`
  }, [date])

  return (
    <React.Fragment>
      <DatePicker
        autoOk
        disableFuture
        label="Review Date"
        className={style.datePicker}
        open={pickerOpen}
        onOpen={() => setPickerOpen(true)}
        onClose={() => setPickerOpen(false)}
        value={date}
        onChange={selectDate}/>

      <MaterialTable
        title={title}
        editable={{
          onRowUpdate: (newData, _) => {
            return updateEntry({ variables: { id: newData.id, data: updatePick(newData) }})
          }
        }}
        columns={[
          { title: 'Employee', field: 'user.fullName', editable: 'never', shrink: true },
          { title: 'Date', field: 'date', editable: 'never', shrink: true },
          { title: 'Minutes', field: 'minutes', type: 'numeric', editable: 'never', shrink: true },
          { title: 'Excluded', field: 'excluded', type: 'numeric', shrink: true },
          { title: 'Notes', field: 'notes' },
        ]}
        data={data ? data.timeclocks : []}
        actions={[
          { icon: TodayIcon, tooltip: "Review Date", isFreeAction: true, onClick: () => setPickerOpen(true) },
          { icon: CloudUploadIcon, tooltip: "Import", isFreeAction: true, onClick: importFile },
        ]}
        isLoading={loading}
        options={{
          paging: false,
        }}
        />
    </React.Fragment>
  )
}

export default Index
